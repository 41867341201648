import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-carta-numerologica',
  templateUrl: './carta-numerologica.component.html',
  styleUrls: ['./carta-numerologica.component.scss']
})
export class CartaNumerologicaComponent implements OnInit {
  public myMath = Math;
  fechaNacimientoInput: FormControl = new FormControl(new Date());
  nombresInput: FormControl = new FormControl('');
  apellidosPaternosInput: FormControl = new FormControl('');
  apellidosMaternosInput: FormControl = new FormControl('');
  isAnalized = false;

  anio = 0;
  mes = 0;
  dia = 0;

  esencia = 0;
  karma = 0;
  vidaPasada = 0;
  personalidad = 0;
  regalo = 0;

  areasDeVida = {
    1: new AreaDeVida(1, 'Estructura del Ego', 0),
    2: new AreaDeVida(2, 'Expresión de las Emociones', 0),
    3: new AreaDeVida(3, 'Expresión al Relacionarse', 0),
    4: new AreaDeVida(4, 'Expresión de lo Material', 0),
    5: new AreaDeVida(5, 'Adaptación al Cambio', 0),
    6: new AreaDeVida(6, 'Relaciones Afectivas', 0),
    7: new AreaDeVida(7, 'La Espiritualidad', 0),
    8: new AreaDeVida(8, 'Manejo de los Talentos', 0),
    9: new AreaDeVida(9, 'Conexión Cósmica', 0)
  };

  impulsoEspiritual = 0;
  misionCosmica = 0;
  fuerza = 0;

  linaje = 0;
  herenciaPsicologica = 0;
  misionFamiliar = 0;
  misionSocial = 0;
  misionEspiritual = 0;

  primerCicloDeVida = new CicloDeVida();
  segundoCicloDeVida = new CicloDeVida();
  tercerCicloDeVida = new CicloDeVida();

  constructor() {
  }

  ngOnInit() {
  }

  calcularCartaNumerologica() {
    const fechaNacimiento: Date = this.fechaNacimientoInput.value;
    this.anio = Number(fechaNacimiento.getFullYear());
    this.mes = Number(fechaNacimiento.getMonth() + 1);
    this.dia = Number(fechaNacimiento.getDate());
    const regalo = Number(fechaNacimiento.getFullYear().toString().substring(2, 4));

    this.esencia = this.sumAllNumbers(this.dia);
    this.karma = this.sumAllNumbers(this.mes);
    this.vidaPasada = this.sumAllNumbers(this.anio);
    this.regalo = this.sumAllNumbers(regalo);
    this.personalidad = this.sumAllNumbers(this.anio + this.mes + this.dia);

    this.isAnalized = true;

    const nombre = this.removeAccentMark(this.nombresInput.value);
    const apellidosPaternos = this.removeAccentMark(this.apellidosPaternosInput.value);
    const apellidosMaternos = this.removeAccentMark(this.apellidosMaternosInput.value);

    this.calculateAreasDeVida(nombre, apellidosPaternos, apellidosMaternos);
    this.calculateImpulsoEspiritual(nombre, apellidosPaternos, apellidosMaternos);
    this.calculateMisionCosmica(nombre, apellidosPaternos, apellidosMaternos);

    this.fuerza = this.sumAllNumbers(this.mes + this.dia);

    this.calculateLinaje(apellidosPaternos, apellidosMaternos);

    this.herenciaPsicologica = this.sumAllNumbers(this.areasDeVida[1].count + this.areasDeVida[2].count +
      this.areasDeVida[3].count + this.areasDeVida[4].count, true, true, false);

    this.calculateMisionFamiliar(nombre, apellidosPaternos, apellidosMaternos);
    this.calculateMisionSocial(nombre, apellidosPaternos, apellidosMaternos);

    this.misionEspiritual = this.sumAllNumbers(this.misionFamiliar + this.misionSocial, false, false, false);

    this.calculateCiclosDeVida();

  }

  countEvolucion(numberAreaDeVida) {
    let count = 0;
    for (const num of [1, 2, 3, 4, 5, 6, 7, 8, 9]) {
      if (this.areasDeVida[num].count === numberAreaDeVida) {
        count = count + 1;
      }
    }

    return this.areasDeVida[numberAreaDeVida].count + count;
  }

  calculateMisionFamiliar(nombres, apellidosPaternos, apellidosMaternos) {
    this.misionFamiliar = 0;
    this.misionFamiliar = this.misionFamiliar + nombres.trim().replace(' ', '').length;
    this.misionFamiliar = this.misionFamiliar + apellidosPaternos.trim().replace(' ', '').length;
    this.misionFamiliar = this.misionFamiliar + apellidosMaternos.trim().replace(' ', '').length;

    this.misionFamiliar = this.stringToNumber('' + this.misionFamiliar + this.areasDeVida[1].count + this.areasDeVida[2].count +
      this.areasDeVida[3].count + this.areasDeVida[4].count);
  }

  calculateMisionSocial(nombres, apellidosPaternos, apellidosMaternos) {
    this.misionSocial = 0;
    this.misionSocial = this.misionSocial + nombres.trim().replace(' ', '').length;
    this.misionSocial = this.misionSocial + apellidosPaternos.trim().replace(' ', '').length;
    this.misionSocial = this.misionSocial + apellidosMaternos.trim().replace(' ', '').length;

    this.misionSocial = this.sumAllNumbers(this.misionSocial + this.areasDeVida[6].count + this.areasDeVida[7].count +
      this.areasDeVida[8].count + this.areasDeVida[9].count, true, true, false);
  }

  calculateAreasDeVida(nombres, apellidosPaternos, apellidosMaternos) {
    this.areasDeVida = {
      1: new AreaDeVida(1, 'Estructura del Ego', 0),
      2: new AreaDeVida(2, 'Expresión de las Emociones', 0),
      3: new AreaDeVida(3, 'Expresión al Relacionarse', 0),
      4: new AreaDeVida(4, 'Expresión de lo Material', 0),
      5: new AreaDeVida(5, 'Adaptación al Cambio', 0),
      6: new AreaDeVida(6, 'Relaciones Afectivas', 0),
      7: new AreaDeVida(7, 'La Espiritualidad', 0),
      8: new AreaDeVida(8, 'Manejo de los Talentos', 0),
      9: new AreaDeVida(9, 'Conexión Cósmica', 0)
    };

    this.countAreasDeVida(nombres);
    this.countAreasDeVida(apellidosPaternos);
    this.countAreasDeVida(apellidosMaternos);

    this.reduceAreasDeVida();

  }

  calculateImpulsoEspiritual(nombres, apellidosPaternos, apellidosMaternos) {
    this.impulsoEspiritual = 0;
    this.sumImpulsoEspiritual(nombres);
    this.sumImpulsoEspiritual(apellidosPaternos);
    this.sumImpulsoEspiritual(apellidosMaternos);

    this.impulsoEspiritual = this.stringToNumber('' + this.impulsoEspiritual);
  }

  calculateMisionCosmica(nombres, apellidosPaternos, apellidosMaternos) {
    const fechaNacimiento: Date = this.fechaNacimientoInput.value;
    const anio = Number(fechaNacimiento.getFullYear());
    const mes = Number(fechaNacimiento.getMonth() + 1);
    const dia = Number(fechaNacimiento.getDate());
    this.misionCosmica = 0;
    this.sumMisionCosmica(nombres);
    this.sumMisionCosmica(apellidosPaternos);
    this.sumMisionCosmica(apellidosMaternos);

    this.misionCosmica = this.misionCosmica + dia + mes + anio;
    this.misionCosmica = this.stringToNumber('' + this.misionCosmica);
  }

  calculateLinaje(apellidosPaternos, apellidosMaternos) {
    this.linaje = 0;
    this.sumLinaje(apellidosPaternos);
    this.sumLinaje(apellidosMaternos);

    this.linaje = this.stringToNumber('' + this.linaje);
  }

  sumMisionCosmica(value) {
    let letterValue = 0;
    for (const val of value.split(' ')) {
      for (const letter of val.split('')) {
        letterValue = this.stringToNumber(letter);
        this.misionCosmica = this.misionCosmica + letterValue;
      }
    }
  }

  sumLinaje(value) {
    let letterValue = 0;
    for (const val of value.split(' ')) {
      for (const letter of val.split('')) {
        letterValue = this.stringToNumber(letter);
        this.linaje = this.linaje + letterValue;
      }
    }
  }

  sumImpulsoEspiritual(value) {
    const vocals = ['a', 'e', 'i', 'o', 'u'];
    let letterValue = 0;
    for (const val of value.split(' ')) {
      for (const letter of val.split('')) {
        if (vocals.indexOf(letter.toLowerCase()) !== -1) {
          letterValue = this.stringToNumber(letter);
          this.impulsoEspiritual = this.impulsoEspiritual + letterValue;
        }
      }
    }
  }

  reduceAreasDeVida() {
    for (const number of [1, 2, 3, 4, 5, 6, 7, 8, 9]) {
      this.areasDeVida[number].count = this.sumAllNumbers(this.areasDeVida[number].count);
    }
  }

  countAreasDeVida(value) {
    for (const val of value.split(' ')) {
      for (const letter of val.split('')) {
        const letterValue = this.stringToNumber(letter);
        this.areasDeVida[letterValue].count = this.areasDeVida[letterValue].count + 1;
      }
    }
  }

  calculateCiclosDeVida() {
    const fechaNacimiento: Date = this.fechaNacimientoInput.value;
    const anio = Number(fechaNacimiento.getFullYear());
    const mes = Number(fechaNacimiento.getMonth() + 1);
    const dia = Number(fechaNacimiento.getDate());

    this.primerCicloDeVida = new CicloDeVida();
    this.primerCicloDeVida.vibracion = this.sumAllNumbers(mes);

    this.primerCicloDeVida.realizacion.push(new Realizacion());
    this.primerCicloDeVida.realizacion[0].vibracion = this.sumAllNumbers(mes + dia);
    this.primerCicloDeVida.realizacion[0].rango.inicio = 0;
    this.primerCicloDeVida.realizacion[0].rango.fin = 36 - this.personalidad;

    this.segundoCicloDeVida = new CicloDeVida();
    this.segundoCicloDeVida.vibracion = this.sumAllNumbers(dia);

    this.segundoCicloDeVida.realizacion.push(new Realizacion());
    this.segundoCicloDeVida.realizacion[0].vibracion = this.sumAllNumbers(dia + anio);
    this.segundoCicloDeVida.realizacion[0].rango.inicio = this.primerCicloDeVida.realizacion[0].rango.fin + 1;
    this.segundoCicloDeVida.realizacion[0].rango.fin = this.primerCicloDeVida.realizacion[0].rango.fin + 9;

    this.segundoCicloDeVida.realizacion.push(new Realizacion());
    this.segundoCicloDeVida.realizacion[1].vibracion = this.sumAllNumbers(this.primerCicloDeVida.realizacion[0].vibracion + this.segundoCicloDeVida.realizacion[0].vibracion);
    this.segundoCicloDeVida.realizacion[1].rango.inicio = this.segundoCicloDeVida.realizacion[0].rango.fin + 1;
    this.segundoCicloDeVida.realizacion[1].rango.fin = this.segundoCicloDeVida.realizacion[0].rango.fin + 9;

    this.tercerCicloDeVida = new CicloDeVida();
    this.tercerCicloDeVida.vibracion = this.sumAllNumbers(anio);

    this.tercerCicloDeVida.realizacion.push(new Realizacion());
    this.tercerCicloDeVida.realizacion[0].vibracion = this.sumAllNumbers(mes + anio);
    this.tercerCicloDeVida.realizacion[0].rango.inicio = this.segundoCicloDeVida.realizacion[1].rango.fin + 1;
    this.tercerCicloDeVida.realizacion[0].rango.fin = 9999999;

  }

  sumAllNumbers(numbers: any, validateMasterNumbers: boolean = true, validateEleven: boolean = true, validateTwelve: boolean = true): number {
    let sum = 0;

    for (const num of ('' + numbers).split('')) {
      sum += Number(num);
    }
    if (sum > 9) {
      if (validateMasterNumbers) {
        if (validateEleven && validateTwelve && sum !== 11 && sum !== 22) {
          sum = this.sumAllNumbers(sum, validateMasterNumbers, validateEleven, validateTwelve);
        }else if (validateEleven && !validateTwelve && sum !== 11 ) {
          sum = this.sumAllNumbers(sum, validateMasterNumbers, validateEleven, validateTwelve);
        } else if (!validateEleven && validateTwelve && sum !== 22) {
          sum = this.sumAllNumbers(sum, validateMasterNumbers, validateEleven, validateTwelve);
        }
      } else {
        sum = this.sumAllNumbers(sum, validateMasterNumbers, validateEleven, validateTwelve);
      }
    }

    return sum;
  }

  stringToNumber(str: string): number {
    let sumLetters = 0;

    for (const letter of str.split('')) {
      sumLetters += this.letterValue(letter);
    }

    if (sumLetters > 9 && sumLetters !== 11 && sumLetters !== 22) {
      sumLetters = this.stringToNumber('' + sumLetters);
    }
    return sumLetters;
  }

  letterValue(str: string): number {
    const anum = {
      a: 1, b: 2, c: 3, d: 4, e: 5, f: 6, g: 7, h: 8, i: 9,
      j: 1, k: 2, l: 3, m: 4, n: 5, o: 6, p: 7, q: 8, r: 9,
      s: 1, t: 2, u: 3, v: 4, w: 5, x: 6, y: 7, z: 8,
      ñ: 5,
    };

    if (!isNaN(Number(str))) {
      return Number(str);
    }
    return anum[str.toLowerCase()];
  }

  getAnioColumn(row: number, column: number): number {
    let result = 0;

    result = this.anio + (9 * (column - 1)) + row;

    return result;
  }

  getAnioPersonal(anioPersonalNumber: number): number {
    let result = 0;

    result = (this.anio + anioPersonalNumber) + this.mes + this.dia;

    result = this.sumAllNumbers(result, false);

    return result;
  }

  getPersonajeAnioPersonal(anioPersonalNumber: number): number {
    let result = 0;

    if (this.anio !== 0 && this.mes !== 0 && this.dia !== 0) {
      result = (this.anio + anioPersonalNumber) + this.mes + this.dia;

      result = this.sumAllNumbers(result, false);

      if (this.areasDeVida !== undefined) {
        return this.areasDeVida[result].count;
      } else {
        return 0;
      }
    }
    return 0;
  }

  removeAccentMark(value: string): string {
    value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    return value;
  }

}

export class AreaDeVida {
  numero: number;
  nombre: string;
  count: number;

  constructor(numero, nombre, count) {
    this.numero = numero;
    this.nombre = nombre;
    this.count = count;
  }
}

export class CicloDeVida {
  vibracion: number;
  realizacion: Array<Realizacion>;

  constructor() {
    this.vibracion = 0;
    this.realizacion = new Array<Realizacion>();
  }
}

export class Realizacion {
  vibracion: number;
  rango: Rango;

  constructor() {
    this.vibracion = 0;
    this.rango = new Rango();
  }
}

export class Rango {
  inicio: number;
  fin: number;

  constructor() {
    this.inicio = 0;
    this.fin = 0;
  }
}
