import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreenciasService {
  private cuestionario;

  constructor(private http: HttpClient) { }

  loadCuestionario() {
    this.http.get('../data/creencias.json').toPromise().then(data => {
      this.cuestionario = data;
    });
  }

  consultaCuestionario(): Observable<any> {
    return this.http.get('../data/creencias.json');
  }
}
