import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cursos-talleres',
  templateUrl: './cursos-talleres.component.html',
  styleUrls: ['./cursos-talleres.component.scss']
})
export class CursosTalleresComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
