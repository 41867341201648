import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AurasService {
  private auras;

  constructor(private http: HttpClient) { }

  loadAurasData() {
    this.http.get('../data/auras.json').toPromise().then(data => {
      this.auras = data;
    });
  }

  getAuras() {
    return this.auras;
  }
}
