export interface PreguntaCreencia {
  id: number;
  descripcion: string;
  value: number;
  isAgree: string;
}

export class CuestionarioCreencia {
  data: Array<PreguntaCreencia>;
}

export class CreenciasConteo {
  id: number;
  conteo: number;
}
