import { Component, OnInit } from '@angular/core';
import { Aura, NombreAura } from 'src/app/models/aura.model';
import { AurasService } from 'src/app/services/auras.service';

@Component({
  selector: 'app-auras-analize',
  templateUrl: './auras-analize.component.html',
  styleUrls: ['./auras-analize.component.scss']
})
export class AurasAnalizeComponent implements OnInit {

  nombresInput = '';
  nombresAurasCalculate: Array<NombreAura> = new Array<NombreAura>();

  constructor(private auraService: AurasService) { }

  ngOnInit() {
  }

  calcularNombreAura() {

    this.nombresAurasCalculate = new Array<NombreAura>();
    const listOfAuras: Array<Aura> = this.auraService.getAuras();
    const nombresArray: Array<string> = this.nombresInput.split(' ');
    let nombreAura = new NombreAura();

    for (const nombre of nombresArray) {
      nombreAura = new NombreAura();

      nombreAura.value = nombre;
      nombreAura.numero = this.stringToNumber(nombre);
      nombreAura.aura = listOfAuras.filter(aura => aura.numero.filter(num => num === nombreAura.numero).length > 0)[0];

      this.nombresAurasCalculate.push(nombreAura);
    }

    if (this.nombresAurasCalculate.length > 0 && nombresArray.length > 1) {
      nombreAura = new NombreAura();
      nombreAura.value = this.nombresInput;
      nombreAura.numero = 0;

      for ( const nombreAuraValue of this.nombresAurasCalculate) {
        nombreAura.numero += nombreAuraValue.numero;
      }

      nombreAura.numero = this.stringToNumber('' + nombreAura.numero);

      nombreAura.aura = listOfAuras.filter(aura => aura.numero.filter(num => num === nombreAura.numero).length > 0)[0];

      this.nombresAurasCalculate.unshift(nombreAura);
    }
  }

  isOnlyLettersNombre(): boolean {
    const letters = /^[a-zA-Z\s]*$/;

    if (this.nombresInput.length === 0) {
      return false;
    }

    if (this.nombresInput.match(letters)) {
      return true;
    } else {
      return false;
    }
  }

  stringToNumber(str: string): number {
    let sumLetters = 0;

    for (const letter of str.split('')) {
      sumLetters += this.letterValue(letter);
    }

    if (sumLetters > 9 && sumLetters !== 11 && sumLetters !== 22) {
      sumLetters = this.stringToNumber('' + sumLetters);
    }

    return sumLetters;
  }

  letterValue(str: string): number {
    const anum = {
      a: 1, b: 2, c: 3, d: 4, e: 5, f: 6, g: 7, h: 8, i: 9,
      j: 1, k: 2, l: 3, m: 4, n: 5, o: 6, p: 7, q: 8, r: 9,
      s: 1, t: 2, u: 3, v: 4, w: 5, x: 6, y: 7, z: 8
    };

    if (!isNaN(Number(str))) {
      return Number(str);
    }
    return anum[str.toLowerCase()];
  }
}
