import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-red-social',
  templateUrl: './red-social.component.html',
  styleUrls: ['./red-social.component.scss']
})
export class RedSocialComponent implements OnInit {
  anio: number;
  constructor() { }

  ngOnInit() {
    this.anio = new Date().getFullYear();
  }

}
