import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AurasAnalizeComponent } from './components/auras-analize/auras-analize.component';
import { HomeComponent } from './components/home/home.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { CursosTalleresComponent } from './components/cursos-talleres/cursos-talleres.component';
import { CreenciasTestComponent } from './components/creencias-test/creencias-test.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import {CartaNumerologicaComponent} from './components/carta-numerologica/carta-numerologica.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'contacto', component: ContactoComponent},
  { path: 'servicios', component: ServiciosComponent },
  { path: 'cursostalleres', component: CursosTalleresComponent },
  {
    path: 'admin', children: [
      { path: '', redirectTo: 'herramientas/auras', pathMatch: 'full' },
      { path: 'herramientas/auras', component: AurasAnalizeComponent },
      { path: 'herramientas/creencias/test', component: CreenciasTestComponent},
      { path: 'herramientas/cartanumerologica', component: CartaNumerologicaComponent}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
