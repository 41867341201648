export interface Aura {
  numero: Array<number>;
  color: string;
  chakra: string;
  propiedadesColor: string;
  utilidad: string;
  descripcionCaracteristicaPersona: string;
  listadoCaracteristicaPersona: Array<string>;
}

export class NombreAura {
  aura: Aura;
  value: string;
  numero: number;
}
