import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AurasAnalizeComponent } from './components/auras-analize/auras-analize.component';
import { LoginComponent } from './components/login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AurasService } from './services/auras.service';
import { HomeComponent } from './components/home/home.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { CursosTalleresComponent } from './components/cursos-talleres/cursos-talleres.component';
import { CreenciasTestComponent } from './components/creencias-test/creencias-test.component';
import { CreenciasService } from './services/creencias.service';
import {MatFormFieldModule, MatNativeDateModule, MatRadioModule} from '@angular/material';
import { RedSocialComponent } from './components/red-social/red-social.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CartaNumerologicaComponent } from './components/carta-numerologica/carta-numerologica.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MaterialModule } from './material/material.module';

const appInitializerFn = (auraService: AurasService) => {
  return () => {
        return auraService.loadAurasData();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    AurasAnalizeComponent,
    LoginComponent,
    HomeComponent,
    ServiciosComponent,
    CursosTalleresComponent,
    CreenciasTestComponent,
    RedSocialComponent,
    ContactoComponent,
    CartaNumerologicaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      autoDismiss: true
    }),
    MaterialModule
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: appInitializerFn,
    multi: true,
    deps: [AurasService, CreenciasService]
  }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
