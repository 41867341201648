import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { fadeAnimation } from './app-animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    fadeAnimation
  ]
})
export class AppComponent {
  title = 'mystic-soul-app';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {

  }

  isActiveRoute(route: string): boolean {
    return this.router.url.includes(route);
  }

  validateAdminRoute(): boolean {
    if (this.router.url.toString().includes('admin')) {
      return true;
    }

    return false;
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
}
