import {AfterViewInit, Component, OnInit} from '@angular/core';
import { CreenciasService } from 'src/app/services/creencias.service';
import {CreenciasConteo, CuestionarioCreencia} from 'src/app/models/creencia.model';
import {equal} from 'assert';

@Component({
  selector: 'app-creencias-test',
  templateUrl: './creencias-test.component.html',
  styleUrls: ['./creencias-test.component.scss']
})
export class CreenciasTestComponent implements OnInit {
  cuestionario: CuestionarioCreencia;
  creencias: Array<CreenciasConteo> = new Array<CreenciasConteo>();

  constructor(private creenciasService: CreenciasService) {
    this.creenciasService.consultaCuestionario().subscribe(data => {
      this.cuestionario = data;
    });
  }

  ngOnInit() {
  }

  initCreencias() {
    this.creencias = new Array<CreenciasConteo>();

    for (let i = 1; i <= 10 ; i++) {
      const creencia = new CreenciasConteo();
      creencia.id = i;
      creencia.conteo = 0;

      this.creencias.push(creencia);
    }
  }

  isButtonDisabled() {
    if (this.cuestionario.data.filter( x => x.isAgree === undefined).length > 0) {
      return true;
    }

    return false;
  }

  contabilizar() {
    this.initCreencias();
    for (const row of this.cuestionario.data) {
      if (row.isAgree !== undefined) {
        this.creencias.filter( x => {
          let mod = row.id % 10;
          if (mod === 0) {
            mod = 10;
          }
          if (x.id === mod) {
            return true;
          }
          return false;
        }).forEach(creencia => {
          const factor = row.isAgree === 'S' && row.value === 1 ? 1 : (row.isAgree === 'N' && row.value === 2 ? 1 : 0);
          creencia.conteo = creencia.conteo + factor;
        });
      }
    }
  }
}
