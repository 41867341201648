import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ContactoService } from '../../services/contacto.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {
  formulario: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private contactoService: ContactoService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.iniciaFormulario();
  }

  get nombreNoValido() {
    return this.formulario.get('nombre').invalid && this.formulario.get('nombre').touched;
  }

  get emailNoValido() {
    return this.formulario.get('email').invalid && this.formulario.get('email').touched;
  }

  get telefonoNoValido() {
    return this.formulario.get('telefono').invalid && this.formulario.get('telefono').touched;
  }

  get telefonoFormatoNoValido() {
    return this.formulario.get('telefono').value.length > 1 && this.telefonoNoValido ? true : false;
  }

  get emailFormatoInvalido() {
    return this.formulario.get('email').value.length > 1 && this.emailNoValido ? true : false;
  }

  get mensajeNoValido() {
    return this.formulario.get('mensaje').invalid && this.formulario.get('mensaje').touched;
  }

  iniciaFormulario() {
    this.formulario = this.formBuilder.group({
      nombre: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]),
      telefono: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]),
      mensaje: new FormControl('', [Validators.required])
    });
  }

  enviaFormulario() {
    if (this.formulario.invalid) {
      return Object.values(this.formulario.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(controls => {
            controls.markAsTouched();
          });
        } else {
          control.markAsTouched();
        }
      });
    } else {
      this.spinner.show();
      this.contactoService.sendEmail(this.formulario.value).subscribe( response => {
        if (response) {
          this.toastr.success('Hemos recibido su mensaje, un administrador se comunicará con ustedes.', 'Notificacion');
        } else {
          this.toastr.error('Ha ocurrido un error. Favor de intentarlo mas tarde.', 'Notificacion');
        }
        this.spinner.hide();
      });
    }
  }

  onlyNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}

